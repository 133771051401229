<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        width="400"
        scrollable
    >

      <v-card>
        <v-card-title>
          Remarque
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">
          {{ remark }}
        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      dialog: false,
      remark: null,
    }
  },
  methods: {
    open(remark) {
      this.dialog = true
      this.remark = remark
    },
    close() {
      this.dialog = false
    },
  },
}
</script>

