<template>
  <div>


    <v-card-title class="text--primary">
      Décassements
      <v-spacer/>
      <v-btn depressed
             dark
             color="gifty"
             @click="$emit('add')">
        <v-icon left>mdi-plus</v-icon>
        Ajouter
      </v-btn>

    </v-card-title>

    <v-divider/>

    <v-card flat>

      <v-card-title>
        <v-btn depressed
               small
               fab
               exact
               @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-spacer/>

        <v-btn text
               color="grey"
               @click="$emit('refresh')"
               class="rounded-lg">
          <v-icon left>mdi-reload</v-icon>
          Actualiser
        </v-btn>

      </v-card-title>

      <v-divider/>

      <v-card-text v-if="isLoading">
        <v-skeleton-loader type="table"/>
      </v-card-text>

      <v-card-text v-else class="pa-0">


        <v-row v-if="disbursements.length"
               class="pa-5">

          <v-col>
            <v-card outlined>
              <v-card-text>
                <div class="d-flex justify-space-between align-center">
                  <div>
                    Total décaissements
                    <span class="d-block font-weight-medium text-no-wrap">
                           {{ disbursements.length }}
                         </span>
                    <v-spacer/>
                  </div>
                  <v-avatar color="gifty">
                    <v-icon dark>mdi-clipboard-list-outline</v-icon>
                  </v-avatar>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card outlined>
              <v-card-text>
                <div class="d-flex justify-space-between align-center">
                  <div>
                    Montant total
                    <span class="d-block font-weight-medium text-no-wrap">
                             {{ CurrencyFormatting(totalAmount()) }} DZD
                         </span>
                    <v-spacer/>
                  </div>
                  <v-avatar color="gifty">
                    <v-icon dark>mdi-cash</v-icon>
                  </v-avatar>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card outlined>
              <v-card-text>
                <div class="d-flex justify-space-between align-center">
                  <div>
                    Montant actuel
                    <span class="d-block font-weight-medium text-no-wrap">
                           {{ CurrencyFormatting(caisse.amount) }} DZD
                         </span>
                    <v-spacer/>
                  </div>
                  <v-avatar color="primary">
                    <v-icon dark>mdi-cash-check</v-icon>
                  </v-avatar>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>

        <v-divider v-if="disbursements.length"/>

        <v-data-table
            v-if="disbursements.length"
            :headers="headers"
            :items="disbursements">

          <template v-slot:item.amount="{ item }">
               <span class="primary--text font-weight-medium">
                     {{ CurrencyFormatting(item.amount) }}
             </span>
          </template>


          <template v-slot:item.remark="{ item }">
            {{ item.remark || '-' }}
          </template>

          <template v-slot:item.date="{ item }">
            <div class="text-no-wrap">
              <v-icon small
                      :style="{marginTop:'-3px'}"
                      color="gifty">mdi-clock-outline
              </v-icon>
              {{ item.date }}
            </div>
          </template>


        </v-data-table>

        <div v-else>
          <NoResults/>
        </div>

      </v-card-text>

    </v-card>


  </div>
</template>

<script>
import NoResults from "@/components/NoResults";

export default {
  props: ['disbursements', 'isLoading', 'caisse'],
  components: {NoResults},
  data() {
    return {
      headers: [
        {text: 'Administration', value: 'user.name'},
        {text: 'Montant', value: 'amount'},
        {text: 'Remarque', value: 'remark'},
        {text: 'Date', value: 'date'},
      ],
    }
  },
  methods: {
    totalAmount() {
      let arr = this.disbursements
      return arr.reduce((accum, item) => accum + item.amount, 0)
    }
  }

};
</script>

<style scoped></style>
