<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        width="400"
        scrollable
    >

      <v-card :loading="isLoading">

        <v-card-title>
          Ajouter une décaissement
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <v-alert type="info" text border="left">
            <span class="font-weight-medium"> Montant actuel :</span>
            {{ CurrencyFormatting(currentAmount) }} DZD
          </v-alert>

          <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="form.date"
                  :error-messages="errors.date"
                  label="Date *"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="form.date"
                @input="menu = false"
            ></v-date-picker>
          </v-menu>

          <v-text-field label="Montant *"
                        :error-messages="errors.amount"
                        suffix="DA"
                        v-model="form.amount"/>

          <v-textarea label="Remarque"
                      rows="2"
                      :error-messages="errors.remark"
                      v-model="form.remark"/>

        </v-card-text>


        <v-divider/>

        <v-card-actions>

          <v-spacer/>

          <v-menu offset-y top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  :loading="isLoading"
                  depressed
                  :disabled="currentAmount===0"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon left>mdi-content-save</v-icon>
                Enregistrer
              </v-btn>
            </template>
            <v-list>

              <v-list-item @click="save">
                <v-list-item-icon>
                  <v-icon color="success">mdi-content-save</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Oui, je valide ce montant
                </v-list-item-title>
              </v-list-item>

              <v-list-item link>
                <v-list-item-icon>
                  <v-icon color="red">mdi-close-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Annuler
                </v-list-item-title>
              </v-list-item>


            </v-list>
          </v-menu>

        </v-card-actions>


      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      dialog: false,
      menu: false,
      isLoading: false,
      currentAmount: 0,

      form: {
        caisse_id: null,
        amount: null,
        remark: null,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      errors: {},
    }
  },
  methods: {
    open(caisseId, currentAmount) {
      this.errors = {}
      this.dialog = true
      this.form.caisse_id = caisseId
      this.currentAmount = currentAmount
    },
    close() {
      this.dialog = false
    },
    save() {
      this.isLoading = true
      HTTP.post('/v1/recoveries/disbursements/store', this.form).then(() => {
        this.close()
        this.$emit('refresh')
        this.$successMessage = 'Cet élément a été enregistré avec succès.'
        this.isLoading = false
      }).catch(err => {
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur s'est produite veuillez corriger."
        this.isLoading = false
        console.log(err)
      })
    }
  },
}
</script>

