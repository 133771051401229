<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        width="400"
        scrollable
    >

      <v-card :loading="isLoading">

        <form @submit.prevent="save">

          <v-card-title>
            Ajouter le montant restant
            <v-spacer/>
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>

          <v-divider/>

          <v-card-text class="pa-4">

            <v-text-field label="Montant restant *"
                          disabled
                          :error-messages="errors.remaining_amount"
                          prepend-icon="mdi-cash-check"
                          suffix="DA"
                          v-model="form.remaining_amount"/>

          </v-card-text>


          <v-divider/>

          <v-card-actions>

            <v-spacer/>

            <v-btn color="primary"
                   :loading="isLoading"
                   depressed
                   type="submit">
              <v-icon left>mdi-content-save</v-icon>
              Enregistrer
            </v-btn>

          </v-card-actions>
        </form>

      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      dialog: false,
      isLoading: false,
      amount: null,
      form: {
        id: null,
        remaining_amount: null
      },
      errors: {},
    }
  },
  methods: {
    open(item) {
      this.form = {
        id: item.id,
        remaining_amount: item.real_amount - item.paid_amount,
      }
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    save() {
      this.isLoading = true
      HTTP.post('/v1/recoveries/recuperators/payment/store-remaining-amount', this.form).then(() => {
        this.close()
        this.$emit('refresh')
        this.$successMessage = 'Cet élément a été enregistré avec succès.'
        this.isLoading = false
      }).catch(err => {
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur s'est produite veuillez corriger."
        this.isLoading = false
        console.log(err)
      })
    }
  },
}
</script>

